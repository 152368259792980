import React, { useState  } from 'react';
import axios from 'axios';
import { Alert } from 'reactstrap';
import Loading from '../Common/Components/Loading';
import * as storage from '../Utils/Storage';
import * as axiosurls from '../axiosurls';


const ValidateToken = ( props ) => {

    const [ error, setError ] = useState( null );

    const tokenNotAuthorisedHandler = props.onTokenNotAuthorised;
    const tokenAuthorisedHandler = props.onTokenAuthorised;

    /**
     * 
     * Call the /validate-token URL to check that the token we've found in localStorage is valid
     * 
     * @param {string} accessToken 
     */
    const validateToken = (accessToken) => {
        axios.get(`${axiosurls.URL_BASE}validate-token`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
            .then( response => {
                if( response.status === 202) {
                    tokenAuthorisedHandler();
                } else {
                    tokenNotAuthorisedHandler();
                }
            })
            .catch( error => {
                tokenNotAuthorisedHandler();
            })
    }

    /**
     * 
     * Check that localStorage is available on this device and validate the token
     * 
     */
    if( error === null ) {
        if( storage.canUseLocalStorage() ) {
            const userData = storage.getUserData();
            if( userData['access_token'] !== null && userData['user_id'] !== null ) {
                validateToken( userData['access_token'] );
            } else {
                tokenNotAuthorisedHandler();
            }
        } else {
            setError( 'This application cannot work without local storage. Please check that your browser is not in Private Browsing mode and your security settings allow local storage.' );
        }
    }

    if( error === null ) {
        return <Loading showWidget="spinner" message="Authorising" useBackground />
    } else {
        return <Alert color="danger">{ error }</Alert>
    }

}

export default ValidateToken;