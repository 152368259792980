import * as userActionTypes from './UserActionTypes';
import * as storage from '../../Utils/Storage';


const initialState = {
    userId: 0,
    userName: '',
    contactId: 0,
    contactName: '',
    loginName: '',
    lastLoginDate: '',
    permissions: '',
    startupView: '',
    savingEnabled: false,
    userLoaded: false,
    isReadOnly: false,
    app: 0,
    alias: {
        id: '0',
        name: ''
    }
}


const parseGrants = ( grants ) => {
    return {
        "canAccess": grants.includes("r"),
        "canCreate": grants.includes("c"),
        "canRead": grants.includes("r"),
        "canUpdate": grants.includes("u"),
        "canDelete": grants.includes("d")
    }
}

/**
 * 
 * Checks all the permissions and if the user doesn't have
 * "cru" access to any of them then return true
 * 
 * @param {Object[]} permissions 
 * 
 * @return {Boolean}
 * 
 */
const isUserReadOnly = ( permissions ) => {
    const permissionData = permissions.data
    for( const p in permissionData ) {
        if( permissionData[p].canCreate || permissionData[p].canUpdate ) {
            return false
        }
    }

    return true
}

const parsePermissions = ( userGroupPermissions, canEditSystem ) => {

    userGroupPermissions = JSON.parse(userGroupPermissions);

    const appPermissions = { 
                             "data" :{
                                "bookings": { ...parseGrants( userGroupPermissions.data['bookings']) }, 
                                "booking_events": { ...parseGrants( userGroupPermissions.data['booking_events']) }, 
                                "itinerary_events": { ...parseGrants( userGroupPermissions.data['itinerary_events']) }, 
                                "evaluation": { ...parseGrants( userGroupPermissions.data['evaluation'])}, 
                                "financials": { ...parseGrants( userGroupPermissions.data['financials'])}, 
                                "contacts": { ...parseGrants( userGroupPermissions.data['contacts'])}, 
                                "contact_viewer": { ...parseGrants( userGroupPermissions.data['contact_viewer'])}, 
                                "reports": { ...parseGrants( userGroupPermissions.data['reports'])}, 
                                "staff": { ...parseGrants( userGroupPermissions.data['staff'])}, 
                                "admin": { ...parseGrants( userGroupPermissions.data['admin'])}
                             },
                             "apps": {
                                "protouree": { "canAccess": userGroupPermissions['apps'].includes('protouree') },
                                "actouree": { "canAccess": userGroupPermissions['apps'].includes('actouree') },
                                "actouree_alias": { "canAccess": userGroupPermissions['apps'].includes('actoureealias') },
                                "system_editor": Number(canEditSystem) === 1 ? true : false
                             }
                           }

    return appPermissions;
}


const userLoadFailed = () => {
    storage.invalidateUser()
    window.location.reload()
}


const reducer = (state = initialState, action) => {
    switch(action.type) {
        case userActionTypes.USER_TOGGLE_AUTOSAVE:
            return {
                ...state,
                savingEnabled: ! state.savingEnabled,
            }
        case userActionTypes.USER_LOADED:
            const permissions = parsePermissions(action.user_group_app_permissions, action.user_can_edit_system)
            return {
                ...state,
                userId: action.user_id,
                userName: action.screen_name,
                loginName: action.user_name,
                lastLoginDate: action.last_login_date,
                lastLoginIP: action.last_login_ip,
                contactId: action.contact_id === null ? '0' : action.contact_id,
                contactName: action.contact_name === null ? '' : action.contact_name,
                startupView: action.startup_view,
                permissions: permissions,
                isReadOnly: isUserReadOnly( permissions ),
                alias: {
                    id: action.contact_id === null ? '0' : action.contact_id,
                    name: action.contact_name === null ? '' : action.contact_name
                },
                userLoaded: true
            }
        case userActionTypes.USER_LOAD_FAILED:
            userLoadFailed()
            return {
                ...state
            }
        case userActionTypes.USER_ALIAS_SET:
            return {
                ...state,
                alias: {
                    id: action.alias_id,
                    name: action.alias_name
                }
            }
        case userActionTypes.USER_ALIAS_REMOVE:
            return {
                ...state,
                alias: {
                    id: state.contactId,
                    name: state.contactName
                }
            }
        case userActionTypes.USER_SET_APP:
            return {
                ...state,
                app: action.app
            }                     
        default:
            return state;
    }
}

export default reducer;