import React, { useState, Suspense } from 'react'
import Authenticate from './Security/Authenticate'
import './style/scss/main.scss'
import Loading from './Common/Components/Loading'
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import projectSelectReducer from './store/reducers/ProjectSelector';
import globalReducer from './store/reducers/Global';
import AutoSaveReducer from './store/AutoSave/AutoSaveReducer';
import AutoSaveResponseReducer from './store/AutoSave/AutoSaveResponseReducer';
import UserReducer from './store/User/UserReducer';

const AppLoader = React.lazy( () => import('./AppLoader') )

const rootReducer = combineReducers({
    projectSelector: projectSelectReducer,
    global: globalReducer,
    autoSave: AutoSaveReducer,
    autosaveResponse: AutoSaveResponseReducer,
    user: UserReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

/**
 * 
 * The App.
 * Checks whether a user is authenticated and, if not, directs them to the Authenticate component.
 * 
 */
const App = () => {

    const [ authenticated, setAuthenticated ] = useState( false );

    const userLoggedIn = () => {
        setAuthenticated( true );
    }

    return (
        <div className="main-container">
            { authenticated ?
                <Suspense fallback={ <Loading showWidget="spinner" message="Loading App" useBackground /> }>
                    <Provider store={store}>
                        <AppLoader />
                    </Provider>
                </Suspense>
            :
                <Authenticate onLoggedIn={ userLoggedIn } />
            }
        </div>
    );
}

export default App;