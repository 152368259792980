const ACCESS_TOKEN_KEY = 'access_token';
const USER_ID_KEY = 'user_id';


/**
 * 
 * Can we use loacalStorage?
 * 
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 * 
 * @return bool
 * 
 */
export const canUseLocalStorage = () => {
    let store = null;
    try {
        store = window['localStorage'];
        var x = '__storage_test__';
        store.setItem(x, x);
        store.removeItem(x);
        return true;
    } catch(e) {
        return e instanceof DOMException && 
                (e.code === 22 || 
                e.code === 1014 || 
                e.name === 'QuotaExceededError' || 
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                (store && store.length !== 0);
    }
}


/**
 * 
 * Fetches the access token from local storage
 * 
 * @return {string}
 * 
 */
export const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}


/**
 * 
 * gets the user's id
 * 
 * @return int
 * 
 */
export const getUserId = () => {
    return localStorage.getItem(USER_ID_KEY);
}


/**
 * 
 * Invalidates the user data - used for logging out
 * 
 */
export const invalidateUser = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(USER_ID_KEY);
}

/**
 * 
 * Stores the user data on login
 * 
 * @param {string} accessToken 
 * @param {int} userId 
 * 
 */
export const storeUserData = ( accessToken, userId ) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken )
    localStorage.setItem(USER_ID_KEY, userId )  
}


/**
 * 
 * Gets the user data
 * 
 * @return array
 * 
 */
export const getUserData = () => {
    const userData = {
        access_token: localStorage.getItem(ACCESS_TOKEN_KEY),
        user_id: localStorage.getItem(USER_ID_KEY),
    }
    return userData;
}



/**
 * 
 * Add other items to the store
 * 
 * @param {array} items 
 */
export const addToStore = ( items ) => {
    for( let key in items ) {
        localStorage.setItem(key, items[key] );
    }
}