import React from 'react'
import { Spinner } from 'reactstrap'
import logoApp from '../../assets/pt-logo-full-2-rgb.png'
import logoComp from '../../assets/protouree-logo.png'
import { Progress } from 'reactstrap'

/**
 * 
 * Displays the Loading message
 * 
 * @param {Object[]} props
 * @param {string} props[].showWidget
 * @param {number} props[].progressValue
 * @param {number} props[].progressMax
 * @param {string} props[].message  
 * @param {Boolean} props[].useBackground
 * 
 */
const Loading = ( props ) => {

    const showWidget = props.showWidget // "progress" or "spinner"
    const progressValue = props.progressValue
    const progressMax = props.progressMax
    const message = props.message
    const useBG = props.useBackground ? true : false

    return (
        <div className={ useBG ? "pt-app-select-page" : null }>
            <div className="app-loading">
        
                <div className="app-loading-text text-center pt-login-dark">
                    <h1>
                        <img src={ useBG ? logoApp : logoComp } className="img-fluid" alt="protouree logo" />
                    </h1>
                    <h5 className="pb-4">Professional touring, entertainment, theatre and event management systems.</h5>
                    <span>{ message }</span>
                    { showWidget === "progress" ?
                        <Progress animated color="info" className="mt-5 progress-bg" value={ progressValue } max={ progressMax }>
                            {( progressValue )}%
                        </Progress>
                    :
                        <Spinner size="sm" color="primary" />
                    }
                </div>
            </div>
        </div>
    )
}

export default Loading;