import * as actionTypes from '../actions/projectSelectorActionTypes';
import * as projectSelectorStatuses from '../../Types/ProjectSelectorStatuses';

const initialState = {
    selectedProjects: [],
    selectedProjectIds: '',
    projectSelectMode: projectSelectorStatuses.PROJECT_MODE_SINGLE,
    projectFilter: projectSelectorStatuses.PROJECT_FILTER_LIVE
}

const reducer = (state = initialState, action) => {

    switch(action.type) {
        case actionTypes.PROJECT_SELECTED:
            return {
                ...state,
                selectedProjects: action.selectedProjects,
                selectedProjectIds: action.selectedProjectIds,
                projectSelectMode: action.projectSelectMode
            }
        case actionTypes.DEFAULT_PROJECT_SELECT:
            return {
                ...state,
                selectedProjects: action.selectedProjects,
                selectedProjectIds: action.selectedProjectIds,
                projectSelectMode: action.projectSelectMode
            }
        case actionTypes.PROJECT_MODE_CHANGED:
            if(action.projectSelectMode === projectSelectorStatuses.PROJECT_MODE_SINGLE) {
                return {
                    ...state,
                    projectSelectMode: action.projectSelectMode,
                    selectedProjectIds: state.selectedProjectIds.split('|')[0],
                    selectedProjects: [state.selectedProjects[0]]
                }    
            } else {
                return {
                    ...state,
                    projectSelectMode: action.projectSelectMode
                }
            }
        case actionTypes.PROJECT_FILTER_CHANGED:
            return {
                ...state,
                projectFilter: action.projectFilter
            }
        default:
            return state;
    }
}

export default reducer;