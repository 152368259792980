import React, { useState } from 'react';
import axios from 'axios';
import { Alert, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import * as axiosurls from '../axiosurls';
import logo from '../assets/pt-logo-full-2-rgb.png'
import * as storage from '../Utils/Storage'

/**
 * 
 * Displays the login form and carries out the login
 * 
 * @param {Object[]} props 
 * 
 */
const LoginForm = ( props ) => {

    const [ loginData, setLoginData ] = useState( { user_name: '', password: '' } );
    const [ error, setError ] = useState( null );
    const [ buttonState, setButtonState ] = useState( 'button' );

    const loggedInHandler = props.onLogin;

    /**
     * 
     * User name or password has changed
     * 
     * @param {object} e 
     * 
     */
    const changeHandler = ( e ) => {
        let data = { ...loginData };
        data[e.target.name] = e.target.value;
        setLoginData( data );
        setError( null );
    }

    const storeUserHandler = ( userData ) => {
        storage.storeUserData( userData.access_token, userData.uid )
        loggedInHandler()
    }

    const login = () => {
        setButtonState( 'spinner' );
        axios.post(`${axiosurls.URL_BASE}login`, { "username": loginData.user_name, "password": loginData.password, "client": "protouree" })
            .then( response => {
                storeUserHandler( response.data )
            })
            .catch( error => {
                if( typeof error.response === 'undefined' ) {
                    setError( 'Login failed. Protouree cannot communicate with the server. Please check your internet connection.' )
                } else {
                    if( error.response.status === 401 ) {
                        setError( 'Incorrect user name or password' );
                    } else {
                        setError( error.message );
                    }
                }
                setButtonState( 'button' );
            })
    }

    const wasEnterKeyPressedHandler = ( e ) => {
        if( e.key === 'Enter') {
            login()
        }
    }


    /**
     * 
     * Login button was clicked
     * 
     */
    const submitHandler = () => {
        const data = { ...loginData };
        let errs = [];
        if( data.user_name.trim() === '' ) {
            errs.push('user name');
        }
        if( data.password.trim() === '' ) {
            errs.push('password');
        }
        if( errs.length > 0 ) {
            setError( `Missing ${errs.join(' and ')}` );
        } else {
            login();
        }
    }


    return (
        <div className="pt-app-select-page">
            <div className="d-flex justify-content-center mb-4">    
                <h1><img src={ logo } className="img-fluid" alt="protouree logo"></img></h1>
            </div>
          
            <Form>
                { error !== null ?
                    <Alert color="danger">{ error }</Alert>
                :
                    null
                }

                <FormGroup className="pt-login-dark">
                    <Label for="user_name">User Name</Label>
                    <Input type="text" name="user_name" id="user_namne" value={ loginData.user_name } onChange={ changeHandler } onKeyDown={ wasEnterKeyPressedHandler } />
                </FormGroup>
                <FormGroup className="pt-login-dark">
                    <Label for="password">Password</Label>
                    <Input type="password" name="password" id="password" value={ loginData.password } onChange={ changeHandler } onKeyDown={ wasEnterKeyPressedHandler } />
                </FormGroup>
                <FormGroup className="mt-5 text-center">
                    { buttonState === 'button' ?
                        <Button color="primary" size="lg" onClick={ submitHandler } className="d-inline-flex align-items-center">
                            <span className="icon-enter3 mr-2"></span>
                            <span class="pb-1">Login</span>
                        </Button>
                    :
                        <Spinner key="authspinner" size="sm" color="primary" />
                    }
                </FormGroup>
            </Form>
            </div>
    )

}

export default LoginForm;