export const GLOBAL_PROJECTS_LOADED = 'GLOBAL_PROJECTS_LOADED';
export const GLOBAL_SUPPORT_DATA_LOADED = 'GLOBAL_SUPPORT_DATA_LOADED';
export const GLOBAL_SYSTEM_SETTINGS_LOADED = 'GLOBAL_SYSTEM_SETTINGS_LOADED';

export const GLOBAL_PROJECT_UPDATED = 'GLOBAL_PROJECT_UPDATED';
export const GLOBAL_PROJECT_PEOPLE_UPDATED = 'GLOBAL_PROJECT_PEOPLE_UPDATED';
export const GLOBAL_PROJECT_ADDED = 'GLOBAL_PROJECT_ADDED';

export const GLOBAL_PRODUCT_UPDATED = 'GLOBAL_PRODUCT_UPDATED';
export const GLOBAL_PRODUCT_ADDED = 'GLOBAL_PRODUCT_ADDED';

export const GLOBAL_CATEGORY_UPDATED = 'GLOBAL_CATEGORY_UPDATED';
export const GLOBAL_CATEGORY_ADDED = 'GLOBAL_CATEGORY_ADDED';

export const GLOBAL_CHANGE_PANE_VISIBILITY = 'GLOBAL_CHANGE_PANE_VISIBILITY';
export const GLOBAL_SHOW_PANE = 'GLOBAL_SHOW_PANE';

export const ACE_PARENT_CATEGORY_ID = 1;
